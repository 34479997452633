import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { Link as RouterLink } from "react-router-dom";

import {
  Box,
  Button,
  Checkbox,
  FormGroup,
  FormControl,
  FormControlLabel,
  Grid,
  Typography,
  TextField,
  TextareaAutosize,
} from "@material-ui/core";
import { ArrowBack, ArrowForward } from "@material-ui/icons";

import { MANAGER_ROUTES } from "../../utils/constants";
import { common, form, navigation } from "../../messages";
import { DatePicker } from "../../ui/DatePicker";

import FormSection from "./input/FormSection";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline"],
    ["clean"],
  ],
  //htmlEditButton: { debug: true, syntax: true },
};

function Navigation() {
  const intl = useIntl();

  return (
    <FormControl margin="normal" fullWidth>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item>
          <Button
            component={RouterLink}
            to={MANAGER_ROUTES.MESSAGES_CAMPAIGNS.replace(":campaignId", "")}
            startIcon={<ArrowBack />}
          >
            {intl.formatMessage(navigation.backToCampaigns)}
          </Button>
        </Grid>
        <Grid item>
          <Button
            component={RouterLink}
            to={MANAGER_ROUTES.HOME}
            startIcon={<ArrowForward />}
          >
            {intl.formatMessage(navigation.goHome)}
          </Button>
        </Grid>
      </Grid>
    </FormControl>
  );
}

export default function MessageCampaingCreateForm({
  handleSubmit,
  title,
  body,
}) {
  const intl = useIntl();
  const [values, setValues] = useState({
    title: title,
    body: body,
  });
  const [valueRichEditor, setValueRichEditor] = useState("");
  const [errorRichEditor, setErrorRichEditor] = useState("");
  const today = new Date();

  const onSubmit = (e) => {
    setErrorRichEditor("");
    e.preventDefault();
    handleSubmit(values);
  };

  const handleChange = useCallback((label) => {
    const updateValue = (val) => {
      setValues((v) => ({ ...v, [label]: val }));
    };

    return (i, f) => {
      if (typeof f === "boolean") updateValue(f);
      else if (i?.target) updateValue(i.target.value);
      else updateValue(i);
    };
  }, []);

  return (
    <Box className="msg">
      <form onSubmit={onSubmit}>
        <FormSection>
          <Box style={{ minHeight: "300px" }}>
            <TextField
              name="title"
              label={intl.formatMessage(form.title)}
              value={values.title}
              onChange={handleChange("title")}
              key="title"
              fullWidth
              margin="normal"
              required
            />
            <Typography
              className="MuiFormLabel-root"
              style={
                errorRichEditor == ""
                  ? { fontSize: "16px", margin: "10px 0px" }
                  : {
                      fontSize: "16px",
                      margin: "10px 0px",
                      color: "red",
                    }
              }
            >
              {"Testo *"}
            </Typography>
            <TextareaAutosize
              name="body"
              label={"Testo"}
              value={values.body}
              onChange={handleChange("body")}
              key="body"
              style={{
                width: "100%",
                height: "200px",
              }}
              aria-label="maximum height"
              placeholder="Inserire testo del messaggio"
            />
            {false && (
              <ReactQuill
                className={errorRichEditor != "" ? "red1d" : ""}
                theme="snow"
                modules={modules}
                value={valueRichEditor}
                placeholder="Inserire il testo del messaggio da inviare"
                onChange={setValueRichEditor}
                error={errorRichEditor == ""}
              />
            )}
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.banner}
                    onChange={handleChange("banner")}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={intl.formatMessage(form.banner)}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.push_notification}
                    onChange={handleChange("push_notification")}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Push Notification"
              />
            </FormGroup>
            <DatePicker
              minDate={today}
              margin="normal"
              fullWidth
              label="Expire At"
              format="yyyy-MM-dd"
              value={values.expire_at}
              onChange={handleChange("expire_at")}
            />
            <TextField
              name="app_link_label"
              label="App Link Label"
              value={values.app_link_label}
              onChange={handleChange("app_link_label")}
              key="app_link_label"
              fullWidth
              margin="normal"
            />
            <TextField
              name="app_link_uri"
              label="App Link URI"
              value={values.app_link_uri}
              onChange={handleChange("app_link_uri")}
              key="app_link_uri"
              fullWidth
              margin="normal"
            />
            <TextField
              name="image_url"
              label="Image URL"
              value={values.image_url}
              onChange={handleChange("image_url")}
              key="image_url"
              fullWidth
              margin="normal"
            />
            <TextField
              name="link_uri"
              label="Link URI"
              value={values.link_uri}
              onChange={handleChange("link_uri")}
              key="link_uri"
              fullWidth
              margin="normal"
            />
          </Box>
        </FormSection>
        <Button variant="contained" fullWidth color="primary" type="submit">
          {intl.formatMessage(title ? common.save : common.create)}
        </Button>
      </form>
      <Navigation />
    </Box>
  );
}
