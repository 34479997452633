import React from "react";
import {
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import FormSection from "../../ui/forms/input/FormSection";
import { ExpandMore, Delete, Edit } from "@material-ui/icons";
import { MANAGER_ROUTES } from "../../utils/constants";
import { useBmapi } from "../../utils/bmapi-context";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { getErrorMessageString } from "../../utils/errors";

export default function MessagesCampaignList({
  campaignId,
  campaigns,
  messages,
}) {
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const intl = useIntl();
  const messagesCampaigns = campaignId
    ? messages.filter((x) => x.message.campaign_id == campaignId)
    : messages;
  const {
    bmapi,
    startLoading,
    stopLoading,
    notifySuccess,
    notifyError,
  } = useBmapi();

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleEdit = (obj) => {
    history.push(
      MANAGER_ROUTES.EDIT_MESSAGE_CAMPAIGN.replace(":messageId?/", obj)
    );
  };

  const handleRemove = (obj) => {
    setOpenDialog(true);
    setMessage(obj);
  };

  const handleConfirm = () => {
    startLoading();
    return bmapi
      .removeMessageCampaign(message)
      .then(() => {
        notifySuccess(
          intl.formatMessage({
            id: "pages.messageCampaign.removed",
            defaultMessage: "Messaggio cancellato con successo",
          })
        );
        location.reload();
        history.push(
          MANAGER_ROUTES.MESSAGES_CAMPAIGNS.replace(":campaignId?/", "")
        );
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
        stopLoading();
      });
  };

  return (
    <Container maxWidth="sm" className="tablistNone">
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Rimuovere il messsaggio selezionato?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annulla
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
      <FormSection>
        {messagesCampaigns.map((option) => (
          <Accordion key={option.message.id}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={option.message.id + "-content"}
              id={option.message.id + "-content"}
            >
              {option.message.name}
              <div style={{ display: "block" }}>
                <Typography variant="h6">{option.message.title}</Typography>
                <Typography variant="caption" color="primary">
                  {campaigns.find(
                    (x) => x.campaign_id == option.message.campaign_id
                  )
                    ? campaigns.find(
                        (x) => x.campaign_id == option.message.campaign_id
                      ).name
                    : "Nessuna campagna"}
                </Typography>
              </div>
              <div style={{ position: "absolute", right: "60px", top: "25px" }}>
                <Edit
                  style={{ margin: "0px 10px" }}
                  onClick={() => {
                    handleEdit(option.message.id);
                  }}
                />
                <Delete
                  style={{ margin: "0px 10px" }}
                  onClick={() => {
                    handleRemove(option.message.id);
                  }}
                />
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{ position: "absolute", right: "50px", top: "20px" }}
              ></div>
              <div style={{ paddingLeft: "20px", display: "block" }}>
                <Typography>
                  <b>Oggetto: </b>
                  {option.message.subject}
                </Typography>
                <Typography>
                  <b>Testo:</b> {option.message.body}
                </Typography>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </FormSection>
    </Container>
  );
}
