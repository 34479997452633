import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography/Typography";
import moment from "moment";
import { checker } from "../../utils/utils";
import { makeStyles } from "@material-ui/core/styles";
import { useBmapi } from "../../utils/bmapi-context";
import { useIntl } from "react-intl";
import FloatingActions, { Action } from "../../ui/FloatingActions";
import { Add } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

function Campaigns() {
  const [events, setEvents] = React.useState([]);
  const [eventTypes, setEventTypes] = React.useState([]);
  const [workers, setWorkers] = React.useState([]);
  const [factories, setFactories] = React.useState([]);
  const [companies, setCompanies] = React.useState([]);
  const [departments, setDepartments] = React.useState([]);
  const [teams, setTeams] = React.useState([]);
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [worker, setWorker] = React.useState("-");
  const [eventType, setEventType] = React.useState("-");
  const [status, setStatus] = React.useState("-");
  const [dialog, setDialog] = React.useState(null);
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const classes = useStyles();
  const intl = useIntl();
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    startLoading();
    Promise.all([
      bmapi.getEvents(),
      bmapi.getWorkers(),
      bmapi.getEventTypes(),
      bmapi.getFactories(),
      bmapi.getDepartments(),
      bmapi.getTeams(),
      bmapi.getCompanies(),
    ])
      .then(([evs, woks, evTs, facs, deps, tms, cmps]) => {
        setEvents(evs);
        setEventTypes(evTs);
        setWorkers(woks);
        setFactories(facs);
        setDepartments(deps);
        setTeams(tms);
        setCompanies(cmps);
      })
      .catch((e) => notifyError(e))
      .finally(stopLoading);
  }, [bmapi, notifyError, startLoading, stopLoading]);

  const Button = () => (
    <button
      type="button"
      className={
        !dialog
          ? ""
          : "bg-blue-700 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      }
      onClick={() => (!dialog ? setDialog("open") : setDialog(null))}
    >
      {!dialog ? "Dettaglio" : "Chiudi"}
    </button>
  );

  const columns = [
    {
      name: "Lavoratore",
      selector: (row) =>
        workers.find((w) => w.id === row.worker_id) !== undefined
          ? workers.find((w) => w.id === row.worker_id).worker_name
          : "",
      style: {
        backgroundColor: "#1d40af",
        color: "white",
      },
    },
    {
      name: "Evento",
      selector: (row) =>
        eventTypes.find((ev) => ev.id === row.event_type_id) !== undefined
          ? eventTypes.find((ev) => ev.id === row.event_type_id).description
          : "",
      style: {
        backgroundColor: "#1d40af",
        color: "white",
      },
    },
    {
      name: "Data e ora (se presente)",
      selector: (row) =>
        row.date && row.start_at && row.end_at
          ? moment(row.date, "YYYYMMDD").format("DD/MM/YYYY") +
            " - Dalle " +
            row.start_at +
            " alle " +
            row.end_at
          : moment(row.date, "YYYYMMDD").format("DD/MM/YYYY"),
      style: {
        backgroundColor: "#1d40af",
        color: "white",
      },
    },
    {
      name: "Stato",
      selector: (row) => (row.status ? row.status : ""),
      style: {
        backgroundColor: "#1d40af",
        color: "white",
      },
    },
    {
      name: "Azioni",
      // eslint-disable-next-line react/display-name
      cell: () => <Button>q</Button>,
      style: {
        backgroundColor: "#4a89c8",
        color: "white",
      },
    },
  ];

  function resetFilter() {
    setEventType("-");
    setWorker("-");
    setStartDate("");
    setEndDate("");
    setStatus("-");
    updateTable(null, null);
    console.log(companies + teams + departments + factories);
  }

  const updateTable = (e, type) => {
    bmapi.getEvents().then(function (data) {
      switch (type) {
        case "WORKER":
          data = checker(data, e, eventType, startDate, endDate, status);
          break;
        case "EVENT_TYPE":
          data = checker(data, worker, e, startDate, endDate, status);
          break;
        case "START_AT":
          data = checker(data, worker, eventType, e, endDate, status);
          break;
        case "END_AT":
          data = checker(data, worker, eventType, startDate, e, status);
          break;
        case "STATUS":
          data = checker(data, worker, eventType, startDate, endDate, e);
          break;
        default:
          break;
      }
      setEvents(data);
    });
  };

  /*useEffect(() => {
    fetch("https://jsc-api-test.oa.r.appspot.com/workers")
      .then((st) => {
        return st.json();
      })
      .then(function (data) {
        setWorkers(data);
        fetch("https://jsc-api-test.oa.r.appspot.com/events")
          .then((st) => {
            return st.json();
          })
          .then(function (data) {
            setEventTypes(data);
            fetch("https://jsc-api-test.oa.r.appspot.com/events")
              .then((st) => {
                return st.json();
              })
              .then(function (data) {
                setEvents(data);
              });
          });
      });
  }, []);*/

  return (
    <div className="p-2 bg-white">
      {dialog != null && (
        <Dialog
          open={!!dialog}
          onClose={() => setDialog(null)}
          fullScreen={true}
          style={{
            padding: "30px",
          }}
        >
          <DialogTitle>Dettaglio Evento Lavoratore</DialogTitle>
          <DialogContent>
            <form>
              <div className="mb-6">
                <label className="text-blue-700 block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Azienda
                </label>
                <Typography>cose</Typography>
              </div>
              <div className="mb-6">
                <label className="text-blue-700 block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Dipartimento
                </label>
                <Typography>cose</Typography>
              </div>
              <div className="mb-6">
                <label className="text-blue-700 block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Lavoratore
                </label>
                <Typography>cose</Typography>
              </div>
              <div className="mb-6">
                <label className="text-blue-700 block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Evento
                </label>
                <Typography>cose</Typography>
              </div>
              <div className="mb-6">
                <label className="text-blue-700 block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Data e ora (se presente)
                </label>
                <Typography>2020/10/01 - Dalle 07:00 alle 12:30</Typography>
              </div>
              <div className="mb-6">
                <label className="text-blue-700 block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Allegati
                </label>
                <Typography>cose</Typography>
              </div>
              <div className="mb-6">
                <label className="text-blue-700 block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Stato notarizzazione
                </label>
                <Typography>Creato</Typography>
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setDialog(null)}
              variant="contained"
              color="primary"
              style={{ margin: "10px" }}
            >
              Chiudi
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <div className="w-full">
        <div className="border border-gray-400 bg-white rounded p-4 flex flex-col justify-between leading-normal">
          <div className="mb-1">
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                margin="dense"
                label={intl.formatMessage({
                  id: "component.campaignEvents.events",
                  defaultMessage: "Filtra per dipartimento",
                })}
                value={worker.id}
                /*onChange={(e) =>
                    setCurrentEvent(
                      events.find((evt) => evt.id === e.target.value)
                    )
                  }*/
                onChange={(e) => {
                  setWorker(workers.find((w) => w.id === e.target.value));
                  updateTable(e.target.value, "WORKER");
                }}
                fullWidth
                select
              >
                {workers.map((evt) => (
                  <MenuItem value={evt.id} key={evt.id}>
                    {evt.worker_name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                margin="dense"
                label={intl.formatMessage({
                  id: "component.campaignEvents.events",
                  defaultMessage: "Filtra per reparto",
                })}
                value={worker.id}
                /*onChange={(e) =>
                    setCurrentEvent(
                      events.find((evt) => evt.id === e.target.value)
                    )
                  }*/
                onChange={(e) => {
                  setWorker(workers.find((w) => w.id === e.target.value));
                  updateTable(e.target.value, "WORKER");
                }}
                fullWidth
                select
              >
                {workers.map((evt) => (
                  <MenuItem value={evt.id} key={evt.id}>
                    {evt.worker_name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                margin="dense"
                label={intl.formatMessage({
                  id: "component.campaignEvents.events",
                  defaultMessage: "Filtra per lavoratore",
                })}
                value={worker.id}
                /*onChange={(e) =>
                    setCurrentEvent(
                      events.find((evt) => evt.id === e.target.value)
                    )
                  }*/
                onChange={(e) => {
                  setWorker(workers.find((w) => w.id === e.target.value));
                  updateTable(e.target.value, "WORKER");
                }}
                fullWidth
                select
              >
                {workers.map((evt) => (
                  <MenuItem value={evt.id} key={evt.id}>
                    {evt.worker_name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                margin="dense"
                label={intl.formatMessage({
                  id: "component.campaignEvents.events",
                  defaultMessage: "Filtra per lavoratore",
                })}
                value={worker.id}
                /*onChange={(e) =>
                    setCurrentEvent(
                      events.find((evt) => evt.id === e.target.value)
                    )
                  }*/
                onChange={(e) => {
                  setWorker(workers.find((w) => w.id === e.target.value));
                  updateTable(e.target.value, "WORKER");
                }}
                fullWidth
                select
              >
                {workers.map((evt) => (
                  <MenuItem value={evt.id} key={evt.id}>
                    {evt.worker_name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                margin="dense"
                label={intl.formatMessage({
                  id: "component.campaignEvents.events",
                  defaultMessage: "Filtra per lavoratore",
                })}
                value={worker.id}
                /*onChange={(e) =>
                    setCurrentEvent(
                      events.find((evt) => evt.id === e.target.value)
                    )
                  }*/
                onChange={(e) => {
                  setWorker(workers.find((w) => w.id === e.target.value));
                  updateTable(e.target.value, "WORKER");
                }}
                fullWidth
                select
              >
                {workers.map((evt) => (
                  <MenuItem value={evt.id} key={evt.id}>
                    {evt.worker_name}
                  </MenuItem>
                ))}
              </TextField>
            </form>

            <ul className="flex-container">
              <li className="mx-1">
                <select
                  value={worker}
                  onChange={(e) => {
                    setWorker(e.target.value);
                    updateTable(e.target.value, "WORKER");
                  }}
                  className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="-">Filtra per lavoratore</option>
                  {workers.map((elem, index) => (
                    <option key={index} value={elem.id}>
                      {elem.worker_name}
                    </option>
                  ))}
                </select>
                <TextField
                  margin="dense"
                  label={intl.formatMessage({
                    id: "component.campaignEvents.events",
                    defaultMessage: "Filtra per lavoratore",
                  })}
                  value={worker.id}
                  /*onChange={(e) =>
                    setCurrentEvent(
                      events.find((evt) => evt.id === e.target.value)
                    )
                  }*/
                  onChange={(e) => {
                    setWorker(workers.find((w) => w.id === e.target.value));
                    updateTable(e.target.value, "WORKER");
                  }}
                  fullWidth
                  select
                >
                  {workers.map((evt) => (
                    <MenuItem value={evt.id} key={evt.id}>
                      {evt.worker_name}
                    </MenuItem>
                  ))}
                </TextField>
              </li>
              <li className="mx-1">
                <select
                  value={eventType}
                  onChange={(e) => {
                    setEventType(e.target.value);
                    updateTable(e.target.value, "EVENT_TYPE");
                  }}
                  className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="-">Filtra per evento</option>
                  {eventTypes.map((elem, index) => (
                    <option key={index} value={elem.id}>
                      {elem.description}
                    </option>
                  ))}
                </select>
              </li>
              <li className="border-solid	border border-gray-400 rounded mx-1">
                <div className="flex">
                  <div className="m-1 ml-4 lg:ml-1">Filtra periodo da</div>
                  <div>
                    <TextField
                      id="dateStart"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue="2022-01-01"
                      className={classes.textField}
                      onChange={(e) => {
                        setStartDate(e.currentTarget.value);
                        updateTable(e.currentTarget.value, "START_AT");
                      }}
                      value={startDate}
                    />
                  </div>
                </div>
              </li>
              <li className="border-solid	border border-gray-400 rounded mx-1">
                <div className="flex">
                  <div className="m-1 ml-4 lg:ml-1">Filtra periodo a</div>
                  <div>
                    <TextField
                      id="dateEnd"
                      type="date"
                      InputLabelProps={{
                        shrink: false,
                      }}
                      defaultValue="2022-12-31"
                      className={classes.textField}
                      onChange={(e) => {
                        setEndDate(e.currentTarget.value);
                        updateTable(e.currentTarget.value, "END_AT");
                      }}
                      value={endDate}
                    />
                  </div>
                </div>
              </li>
              <li className="mx-1">
                <select
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                    updateTable(e.target.value, "STATUS");
                  }}
                  className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="-">Filtra per stato</option>
                  <option value="CREATED">CREATED</option>
                </select>
              </li>
              <li className="my-2 mx-1 lg:my-0">
                <button
                  onClick={resetFilter}
                  className="block appearance-none w-full bg-blue-700 text-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                >
                  Pulisci filtri
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <DataTable columns={columns} data={events} pagination />
      {openDialog}
      <FloatingActions>
        <Action
          icon={<Add />}
          label="Crea utente"
          action={() => setOpenDialog(true)}
        />
      </FloatingActions>
    </div>
  );
}

export default Campaigns;
