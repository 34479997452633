import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import SearchIcon from "@material-ui/icons/Search";
import Typography from "@material-ui/core/Typography/Typography";
import moment from "moment";
import { checker, backDays } from "../../utils/utils";
import { makeStyles } from "@material-ui/core/styles";
import { useBmapi } from "../../utils/bmapi-context";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

function Campaigns() {
  const [events, setEvents] = React.useState([]);
  const [eventTypes, setEventTypes] = React.useState([]);
  const [workers, setWorkers] = React.useState([]);
  const [factories, setFactories] = React.useState([]);
  const [companies, setCompanies] = React.useState([]);
  const [departments, setDepartments] = React.useState([]);
  const [teams, setTeams] = React.useState([]);
  const [startDate, setStartDate] = React.useState(backDays(7));
  const [endDate, setEndDate] = React.useState(backDays(0));
  const [worker, setWorker] = React.useState("-");
  const [factory, setFactory] = React.useState("-");
  const [department, setDepartment] = React.useState("-");
  const [team, setTeam] = React.useState("-");
  const [eventType, setEventType] = React.useState("-");
  const [status, setStatus] = React.useState("-");
  const [dialog, setDialog] = React.useState(null);
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const [searchDone, setSearchDone] = React.useState(false);
  const classes = useStyles();
  const intl = useIntl();

  useEffect(() => {
    startLoading();
    Promise.all([
      bmapi.getEvents(),
      bmapi.getWorkers(),
      bmapi.getEventTypes(),
      bmapi.getFactories(),
      bmapi.getDepartments(),
      bmapi.getTeams(),
      bmapi.getCompanies(),
    ])
      .then(([evs, woks, evTs, facs, deps, tms, cmps]) => {
        setEvents(checker(evs, "", "", startDate, endDate, ""));
        setEventTypes(evTs);
        setWorkers(woks);
        setFactories(facs);
        setDepartments(deps);
        setTeams(tms);
        setCompanies(cmps);
      })
      .catch((e) => notifyError(e))
      .finally(stopLoading);
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      name: "Squadra",
      selector: (row) =>
        workers.find((w) => w.id === row.worker_id) !== undefined
          ? teams.find(
              (t) =>
                t.id === workers.find((w) => w.id === row.worker_id).team_id
            ).name
          : "",
      style: {
        backgroundColor: "#1d40af",
        color: "white",
      },
    },
    {
      name: "Lavoratore",
      selector: (row) =>
        workers.find((w) => w.id === row.worker_id) !== undefined
          ? workers.find((w) => w.id === row.worker_id).worker_name
          : "",
      style: {
        backgroundColor: "#1d40af",
        color: "white",
      },
    },
    {
      name: "Evento",
      selector: (row) =>
        eventTypes.find((ev) => ev.id === row.event_type_id) !== undefined
          ? eventTypes.find((ev) => ev.id === row.event_type_id).description
          : "",
      style: {
        backgroundColor: "#1d40af",
        color: "white",
      },
    },
    {
      name: "Data e ora (se presente)",
      selector: (row) =>
        row.date && row.start_at && row.end_at
          ? moment(row.date, "YYYYMMDD").format("DD/MM/YYYY") +
            " - Dalle " +
            row.start_at +
            " alle " +
            row.end_at
          : moment(row.date, "YYYYMMDD").format("DD/MM/YYYY"),
      style: {
        backgroundColor: "#1d40af",
        color: "white",
      },
    },
    {
      name: "Azioni",
      // eslint-disable-next-line react/display-name
      cell: () => (
        <IconButton
          component="label"
          style={{
            color: "white",
          }}
          onClick={() => (!dialog ? setDialog("open") : setDialog(null))}
        >
          <SearchIcon />
        </IconButton>
      ),
      style: {
        backgroundColor: "#4a89c8",
      },
    },
  ];

  {
    /*} <Button
          type="button"
          className={
            !dialog
              ? ""
              : "backroung-blue-700 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          }
          onClick={() => (!dialog ? setDialog("open") : setDialog(null))}
        >
          Dettaglio
        </Button>
         <Button variant="contained" component="label">
         Upload
         <input hidden accept="image/*" multiple type="file" />
       </Button>*/
  }

  function resetFilter() {
    setEventType("-");
    setWorker("-");
    setFactory("-");
    setDepartment("-");
    setTeam("-");
    setStartDate("");
    setEndDate("");
    setStatus("-");
    updateTable(null, null);
    console.log(companies + teams + departments + factories);
  }

  const updateTable = () => {
    bmapi.getEvents().then(function (data) {
      data = checker(data, worker, eventType, startDate, endDate, status);
      setEvents(data);
    });
  };

  return (
    <div className="p-2 bg-white">
      {dialog != null && (
        <Dialog
          open={!!dialog}
          onClose={() => setDialog(null)}
          fullScreen={true}
          style={{
            padding: "30px",
          }}
        >
          <DialogTitle>Dettaglio Evento Lavoratore</DialogTitle>
          <DialogContent>
            <form>
              <div className="mb-6">
                <label className="text-blue-700 block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Azienda
                </label>
                <Typography>cose</Typography>
              </div>
              <div className="mb-6">
                <label className="text-blue-700 block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Dipartimento
                </label>
                <Typography>cose</Typography>
              </div>
              <div className="mb-6">
                <label className="text-blue-700 block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Lavoratore
                </label>
                <Typography>cose</Typography>
              </div>
              <div className="mb-6">
                <label className="text-blue-700 block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Evento
                </label>
                <Typography>cose</Typography>
              </div>
              <div className="mb-6">
                <label className="text-blue-700 block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Data e ora (se presente)
                </label>
                <Typography>2020/10/01 - Dalle 07:00 alle 12:30</Typography>
              </div>
              <div className="mb-6">
                <label className="text-blue-700 block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Allegati
                </label>
                <Typography>cose</Typography>
              </div>
              <div className="mb-6">
                <label className="text-blue-700 block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Stato notarizzazione
                </label>
                <Typography>Creato</Typography>
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setDialog(null)}
              variant="contained"
              color="primary"
              style={{ margin: "10px" }}
            >
              Chiudi
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="border border-gray-400 bg-white rounded p-4 flex flex-col justify-between leading-normal">
          <div className="mb-1">
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                margin="dense"
                label={intl.formatMessage({
                  id: "component.campaignEvents.events",
                  defaultMessage: "Filtra per stabilimento",
                })}
                value={factory.name}
                onChange={(e) => {
                  setSearchDone(true);
                  setWorker(factories.find((f) => f.id === e.target.value));
                }}
                fullWidth
                select
              >
                {factories.map((evt) => (
                  <MenuItem value={evt.id} key={evt.id}>
                    {evt.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                margin="dense"
                label={intl.formatMessage({
                  id: "component.campaignEvents.events",
                  defaultMessage: "Filtra per reparto",
                })}
                value={department.id}
                onChange={(e) => {
                  setSearchDone(true);
                  setDepartment(
                    departments.find((d) => d.id === e.target.value)
                  );
                }}
                fullWidth
                select
              >
                {departments.map((dep) => (
                  <MenuItem value={dep.id} key={dep.id}>
                    {dep.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                margin="dense"
                label={intl.formatMessage({
                  id: "component.campaignEvents.events",
                  defaultMessage: "Filtra per team",
                })}
                value={team.id}
                onChange={(e) => {
                  setSearchDone(true);
                  setTeam(teams.find((t) => t.id === e.target.value));
                }}
                fullWidth
                select
              >
                {teams.map((tm) => (
                  <MenuItem value={tm.id} key={tm.name}>
                    {tm.name}
                  </MenuItem>
                ))}
              </TextField>
              <Autocomplete
                onChange={(event, newValue) => {
                  setSearchDone(true);
                  setWorker(workers.find((w) => w.id === newValue.id));
                }}
                id="combo-box-demo"
                options={workers}
                style={{ display: "inline-block" }}
                getOptionLabel={(option) => option.worker_name}
                renderInput={(params) => (
                  <TextField {...params} label="Filtra per lavoratore" />
                )}
                /*onChange={(e) => {
                  setWorker(workers.find((w) => w.id === e.target.value));
                  updateTable(e.target.value, "WORKER");
                }}*/
              />
              <TextField
                margin="dense"
                label={intl.formatMessage({
                  id: "component.campaignEvents.events",
                  defaultMessage: "Filtra per evento",
                })}
                value={eventType.id}
                onChange={(e) => {
                  setSearchDone(true);
                  setEventTypes(
                    eventTypes.find((w) => w.id === e.target.value)
                  );
                }}
                fullWidth
                select
              >
                {eventTypes.map((evt) => (
                  <MenuItem value={evt.id} key={evt.id}>
                    {evt.description}
                  </MenuItem>
                ))}
              </TextField>
              <div style={{ width: "100%" }}>
                <div style={{ display: "inline-block" }}>
                  <div>Filtra periodo da</div>
                  <div>
                    <TextField
                      id="dateStart"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className={classes.textField}
                      onChange={(e) => {
                        setSearchDone(true);
                        setStartDate(e.currentTarget.value);
                      }}
                      value={startDate}
                    />
                  </div>
                </div>
                <div style={{ display: "inline-block" }}>
                  <div>Filtra periodo a</div>
                  <div>
                    <TextField
                      id="dateEnd"
                      type="date"
                      InputLabelProps={{
                        shrink: false,
                      }}
                      className={classes.textField}
                      onChange={(e) => {
                        setSearchDone(true);
                        setEndDate(e.currentTarget.value);
                      }}
                      value={endDate}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    justifyContent: "flex-start",
                    margin: "-35px 0px 15px",
                  }}
                >
                  <Button
                    color="primary"
                    onClick={() => {
                      setSearchDone(false);
                      updateTable();
                    }}
                    variant="contained"
                    disabled={!searchDone}
                    style={{ marginRight: "10px" }}
                  >
                    Ricerca
                  </Button>
                  <Button
                    onClick={resetFilter}
                    style={{
                      background: "#1d40af",
                      color: "white",
                      marginRight: "10px",
                    }}
                    variant="contained"
                    type="submit"
                    disabled={!true}
                  >
                    Pulisci filtri
                  </Button>
                </div>
              </div>
            </form>
          </div>
          <DataTable
            columns={columns}
            data={events}
            pagination
            style={{ border: "1px solid blue", borderRadius: "10px" }}
          />
        </div>
      </div>
    </div>
  );
}

export default Campaigns;
